import type { AdditionalCampaignData } from ".";

export default {
  campaignId: 8,
  title: "Waste collection in coastal regions to stop ocean plastic",
  posterImg: "/images/projects-carousel/plastic.jpg",
  impactTitle: "PLASTIC REMOVED",
  impactUnit: "lbs",
  ctaTitle: "PLASTIC REMOVED",
  impactLabel: "WASTE COLLECTION IN COASTAL REGIONS TO STOP OCEAN PLASTIC",
  dollarImpact: "Removes 10 lbs of plastic from coastal areas",
  bgColor: "bg-ocean",
  impactScore: 88,
  icon: "/images/emojis/ocean.png",
  path: "/campaigns/cleanhub",
  shortVideoId: "1069251007?h=95dfd4aad9",
  shortVideoIdMobile: "1069251053?h=a92c928945",
  featuredReview: {
    name: "Matthew Mulrennan",
    photo: "/images/who-we-are-page/advisors/mulrennan.jpg",
    title:
      "Cleantech Venture Partner; former Director at XPRIZE Ocean Initiative",
    quote:
      "The world is already overwhelmed with plastic pollution, and looking for better waste management and upstream solutions is really the way to go. We can't clean up all plastic when it hits the ocean.",
  },
} satisfies AdditionalCampaignData;
